@import '../../../../styles/styles';

.guestLabel {
  color: $blue-extra-dark;
  font-weight: 600;
}

.radioControls {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 1rem 0 1rem 1rem;

  label {
    display: inline-block;
    height: 2rem;
    margin-right: 2rem;
    position: relative;
  }
  > div:last-of-type {
    label {
      margin-right: 0;
    }
  }
}

.petsContainer {
  @include searchPageOnly {
    @include mediaQuery(max, $tablet) {
      @include touchHilight(200ms);
    }
  }
}
