.radio {
  position: relative;

  input {
    opacity: 0;

    &:checked + label:before {
      background-image: url('/assets/icons/icon-radioChecked.svg');
    }
    &:disabled + label {
      opacity: 0.6;
    }
  }
  label {
    line-height: 2rem;
    padding-left: 2rem;

    &:before {
      background-image: url('/assets/icons/icon-radio.svg');
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      content: '';
      cursor: pointer;
      display: inline-block;
      position: absolute;
      width: 1.4375rem;
      height: 1.4375rem;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
