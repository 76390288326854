@import '../../styles/styles';

.filterBarWrapper {
  background-color: $glacier;
  padding: 1rem;
  width: 100%;

  @include mediaQuery(max, $tablet) {
    padding: 0.5rem 1rem;
  }
}

.filterBar {
  display: flex;
  margin: 0 auto;
  width: 100%;

  > div {
    height: 2.5rem;
    margin-right: 0.5rem;
  }

  &.filterBarDesktop {
    @include mediaQuery(max, $tablet) {
      display: none;
    }
  }

  &.filterBarMobile {
    justify-content: flex-start;

    > div {
      margin-right: 0.5rem;
    }

    @include mediaQuery(min, $tablet + 1) {
      display: none;
    }
  }
}

.mobileFilters {
  background-color: $white;
  bottom: 0;
  display: none;
  height: 100% !important;
  left: 0;
  overflow-y: auto;
  padding-top: 3rem;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 7;

  &.mobileFiltersOpen {
    display: block;

    @include searchPageOnly {
      > div {
        &:last-of-type {
          border-bottom: none;
        }
      }
    }
  }

  header {
    align-items: center;
    background-color: $white;
    border-bottom: 1px solid rgba(10, 63, 70, 0.1);
    display: flex;
    font-size: 0.75rem;
    justify-content: space-between;
    left: 0;
    padding: 1rem;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 7;

    > div {
      display: flex;
      flex: 1;
      justify-content: center;

      &:first-of-type button {
        margin-right: auto;
      }
      &:nth-of-type(2) {
        min-width: 10rem;
      }
      &:last-of-type button {
        margin-left: auto;
      }
    }
    .btn {
      background-color: transparent;
      border: none;
      color: $blue;
      font-size: 0.75rem;
      padding: 0;

      &:disabled {
        opacity: 0.5;
      }
    }

    .headerLabel {
      color: $blue-dark;
      font-size: 0.75rem;
      font-weight: 600;
    }
  }

  h3 {
    font-family: $font-primary;
    font-size: 0.75rem;
    font-weight: 700;
    margin-bottom: 1.25rem;
    letter-spacing: 1px;
    text-transform: uppercase;
  }

  h4 {
    color: $casal;
    font-size: 1rem;
    font-weight: 400;
    letter-spacing: 0;
    text-transform: none;
  }

  > div {
    border-bottom: 1px solid $grey-very-light;
    padding: 2rem 1rem;

    &:last-of-type {
      margin-bottom: 4rem;
    }

    > h3:nth-of-type(2) {
      margin-top: 2rem;
    }
  }

  input[type='checkbox'] + label {
    color: $casal;
    font-size: 1rem;
    font-weight: 400;
    letter-spacing: 0;
    margin-bottom: 0.5rem;
    text-transform: none;
    width: 100%;

    &:before {
      position: absolute;
      right: 0;
    }
  }

  footer {
    background-color: $white;
    border-top: 1px solid rgba(41, 51, 64, 0.15);
    bottom: 0;
    left: 0;
    padding: 0.5rem 1rem;
    position: fixed;
    right: 0;

    button {
      width: 100%;
    }
  }
}

.mobileFilterTrigger {
  background-color: $white;
  border-radius: 4px;
  border: 1px solid $inputBorder;
  color: $casal;
  font-size: 0.75rem;
  font-weight: 400;
  height: 2rem;
  line-height: normal;
  padding: 0.5rem 0.625rem;

  @include searchPageOnly {
    padding: 0.5rem 2.15rem;
    @include searchFilterCopy;
    @include verticalAlign;
    @include noWebkitHilight;
  }
}
