// @todo [cleanup] - remove any font weights that aren't required
// TODO: Change instances of 1023px breapoints to use $smDesktop / General breakpoint cleanup
//@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&family=Nunito:wght@300;400;600;700;800;900&display=swap');

// Screen Sizes
$lgDesktop: 1440px;
$desktop: 1280px;
$mdDesktop: 1205px;
$smDesktop: 1024px;
$lgTablet: 1000px;
$tablet: 900px;
$smTablet: 768px;
$mobile: 600px;
$smallMobile: 375px;
$searchPageMobile: 865px;
$searchPageHamburger: 1255px;

// Max Widths for Content
$maxContentWidth: 1095px;

// Typography
$font-primary: 'Montserrat', sans-serif;
$font-secondary: 'Nunito', sans-serif;
$font-tertiary: 'Montserrat', sans-serif;
$font-bold: 600;
$font-bolder: 700;
// Animations
$carouselTransition: transform 550ms cubic-bezier(0.455, 0.3, 0.515, 0.955);

// Transitions
$standardTransitionDuration: 300ms;
$standardTransitionTimingFunction: ease-in-out;
$standardTransition: $standardTransitionDuration
  $standardTransitionTimingFunction;

// Shadows
$standardBoxShadow: 0 0.25rem 1rem 0 rgba(0, 0, 0, 0.1);
$cardBoxShadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
$box-shadow: 0 7px 15px 0 rgba(9, 63, 70, 0.15),
  0 25px 15px 0 rgba(9, 63, 70, 0.06);
$grey-dot-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
$favoriteBtnBoxShadow: 0 0.25rem 1rem 0 rgba(0, 0, 0, 0.5);
$favoriteToastBoxShadow: 0 10px 20px 5px rgba(0, 0, 0, 0.2);
$toastBoxShadow: 4px 4px 20px rgba(0, 0, 0, 0.25),
  -4px -4px 20px rgba(0, 0, 0, 0.25);
