@import '../../../../styles/styles';
@import '../../../../styles/select';

.select {
  @extend .select-small;

  .value {
    width: 100%;
    position: relative;
    span {
      margin-right: 0.5rem;
      pointer-events: none;
    }
    svg {
      pointer-events: none;
    }

    &.focused {
      span {
        font-weight: bold;
      }
      border: 3px solid $inputBorder;
    }
  }

  .options {
    padding: 2rem 2rem 0;
    top: 8px;
    border-radius: 4px;
    top: calc(100% + 30px);
    min-width: 350px;

    @include mediaQuery(max, $tablet) {
      align-items: center;
      display: flex;
      justify-content: space-between;
      padding: 0 0 1rem;
    }

    h3 {
      font-family: $font-primary;
      font-size: 0.75rem;
      font-weight: 700;
      letter-spacing: 1px;
      margin-bottom: 1rem;
      text-transform: uppercase;

      @include mediaQuery(max, $tablet) {
        color: $casal;
        font-size: 1rem;
        font-weight: 400;
        letter-spacing: 0;
        margin-bottom: 0;
        text-transform: none;
      }
    }
  }

  .footer {
    border-top: none;
    margin-top: 1.5rem;
  }
}

.hidden {
  display: none;
}
