@import '../../../styles/styles';

.defaultTextStyle {
  font-family: $font-primary;
  color: $saldova;
}

@mixin centerStarsVertical {
  margin: -26px 0px 16px 0px;
}

@mixin centerStarsVerticalMobile {
  margin: -10px 0px 16px 0px;
}

@mixin centerStarsVerticalDates {
  margin: -10px 0px 10px 0px;
}

.result {
  background-color: $white;
  box-shadow: $standardBoxShadow;
  display: flex;
  flex: 0 0 100%;
  margin-bottom: 20px;
  max-width: 100%;
  border-radius: 0px 4px 4px 0px;

  &:first-child {
    margin-top: 10px;
  }

  @include mediaQuery(max, $mobile) {
    flex-direction: column;
    border-radius: 0px 0px 4px 4px;
  }
}

.reviewResult {
  @extend .result;
  box-shadow: none;
  height: 179px;
  margin: 20px 0;
  max-width: 607px;

  @include mediaQuery(max, 625px) {
    flex-direction: column;
    height: 100%;
  }
}

.imagesWrapper {
  width: 100%;
  height: 100%;

  img {
    border-radius: 4px 0px 0px 4px;

    @include mediaQuery(max, $mobile) {
      border-radius: 4px 4px 0px 0px;
    }
  }
}

.resultImage {
  background-color: $grey-transparent;

  // 16:9 aspect ratio
  @include mediaQuery(min, $mobile) {
    height: auto;
    min-height: 180px;
    width: 320px;
  }

  // Flex to image height
  @include mediaQuery(max, $mobile) {
    display: flex;
    width: 100%;
    align-self: center;
    position: relative;
  }

  img:not(.comingSoonImg) {
    display: block;
    height: 100%;
    width: 100%;
  }

  &.hasDates {
    @include mediaQueryAnd($lgTablet + 1, 1200px) {
      height: auto;
    }
    @include mediaQueryAnd($mobile, 800px) {
      height: auto;
    }
  }
}

.resultImageReview {
  @extend .resultImage;

  flex: 0 0 244px;
  width: 244px;
  height: 180px;

  & > div {
    height: 100%;
    width: 100%;
  }

  @include mediaQuery(max, 625px) {
    flex: none;
    max-height: 220px;
    max-width: 100%;
    position: relative;
    width: 100%;
  }

  img {
    height: 100%;
    border-radius: 4px;
    @include mediaQuery(max, 625px) {
      max-height: 220px;
    }
  }
}

.comingSoon {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;

  span {
    color: $saldova;
    display: block;
    font-size: 16px;
    font-style: italic;
    line-height: 18px;
    margin-top: 24px;
  }
}

.resultImageType {
  background-color: $dark-cerulean;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  bottom: 0;
  color: $white;
  display: none;
  font-size: 0.75rem;
  font-weight: 600;
  max-width: 150px;
  left: 23px;
  letter-spacing: 0.83px;
  line-height: 12px;
  max-width: 150px;
  padding: 5px 8px 5px;
  position: absolute;
  text-transform: uppercase;

  @include mediaQuery(max, $mobile) {
    display: inline-block;
    z-index: 5;
    position: absolute;
  }
}

.resultReviewImageType {
  @extend .resultImageType;

  @include mediaQuery(max, 625px) {
    display: inline-block;
    z-index: 1;
  }
}

.resultDetails {
  box-sizing: border-box;
  color: $saldova;
  display: block;
  flex: 1;
  max-width: calc(100% - 320px);
  padding: 10px 24px;
  text-decoration: none;

  @include mediaQuery(max, 960px) {
    padding: 5px 10px;
  }

  @include mediaQuery(max, $mobile) {
    max-width: 100%;
    padding: 15px 20px;
  }
}

.resultReviewDetails {
  @extend .resultDetails;

  max-width: calc(100% - 244px);
  padding: 10px 22px;

  @include mediaQuery(max, 625px) {
    max-width: 100%;
    padding: 15px 20px;
  }
}

.resultDetailsDescription {
  box-sizing: border-box;
}

.resultType {
  @extend .defaultTextStyle;
  display: inline-block;
  background-color: $dark-cerulean;
  border-radius: 3px;
  color: $white;
  font-size: 10px;
  font-weight: 600;
  max-width: 150px;
  letter-spacing: 0.83px;
  line-height: 12px;
  padding: 5px 8px 5px;
  text-transform: uppercase;
  margin: 10px 0px 5px;

  @include mediaQuery(max, $mobile) {
    display: none;
  }
}

.resultReviewType {
  @extend .resultType;

  @include mediaQuery(max, 625px) {
    display: none;
  }
}

.resultDetailsDescriptionChild {
  max-width: 100%;
}

.resultHeadline {
  @extend .defaultTextStyle;
  @include Ellipsis;
  display: block;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  margin-top: 5px;
}

.resultReviewHeadline {
  display: block;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  margin-top: 5px;
}

.resultLocation {
  display: block;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  margin: 3px 0;
  padding: 3px 0;
  color: $saldova;
}

.resultReviewLocation {
  @extend .resultLocation;
  color: $bodyText;
  margin: 10px 0;
}

.resultDetailInfo {
  @extend .defaultTextStyle;
  align-items: center;
  display: flex;
  max-width: 800px;
  padding-bottom: 16px;

  &:last-child {
    margin-top: 3px;
  }

  span {
    color: $saldova;
    display: block;
    font-family: $font-primary;
    font-weight: 400;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    margin-right: 1px;
    padding: 0 10px 0 1px;
    position: relative;

    &:after {
      bottom: 25%;
      color: $casal;
      font-size: 18px;
      content: '.';
      position: absolute;
      right: 2%;
    }

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      &:after {
        content: '';
      }
    }
  }
}

.resultReviewDetailInfo {
  @extend .resultDetailInfo;

  span {
    font-size: 16px;
    line-height: 18px;
  }
}

.resultDetailBottom {
  align-items: baseline;
  display: flex;
  justify-content: space-between;
  margin-top: 28px;
  width: 100%;
  padding-bottom: 10px;

  &.alignEnd {
    align-items: flex-end;
  }

  @include mediaQuery(max, $mobile) {
    margin-top: 17px;
  }

  // Custom media queries to break stars above price
  &:not(.favoritesResultDetailBottom) {
    @include mediaQueryAnd($lgTablet, 1115px) {
      @include reverseColumn;
      > .resultDetailRating {
        @include centerStarsVertical;
      }
    }

    @include mediaQueryAnd($mobile, 750px) {
      @include reverseColumn;
      > .resultDetailRating {
        @include centerStarsVertical;
      }
    }

    @include mediaQuery(max, 500px) {
      @include reverseColumn;
      > .resultDetailRating {
        @include centerStarsVerticalMobile;
      }
    }
  }

  &.hasDates {
    margin-top: 10px;

    @include mediaQueryAnd($lgTablet, 1300px) {
      @include reverseColumn;
      > .resultDetailRating {
        @include centerStarsVerticalDates;
      }
    }

    @include mediaQueryAnd($mobile, 850px) {
      @include reverseColumn;
      > .resultDetailRating {
        @include centerStarsVerticalDates;
      }
    }

    @include mediaQuery(max, 500px) {
      @include reverseColumn;
      > .resultDetailRating {
        @include centerStarsVerticalDates;
      }
    }
  }
}

.resultDetailRating {
  display: flex;
  align-items: baseline;

  span {
    font-family: $font-primary;
    font-size: 14px;
    line-height: 17px;
    font-weight: 400;
    color: $saldova;
  }

  &.hasDates {
    @include mediaQueryAnd(1001px, 1200px) {
      margin: 10px 0;
    }
    @include mediaQueryAnd($mobile, 800px) {
      margin-bottom: 10px;
    }
    @include mediaQuery(max, 450px) {
      margin: 10px 0;
    }
  }
}

.starRating {
  display: flex;
  align-items: baseline;
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  margin-right: 4px;

  span {
    font-size: 14px;
    font-weight: 600;
    display: block;
    font-family: $font-primary;
    margin-right: 4px;
    margin-top: 2px;
  }
}

.reviews {
  color: $casal;
  font-size: 16px;
  font-weight: 400;
  font-family: $font-primary;
}

.avgPrice {
  font-family: $font-primary;
  font-size: 14px;
  line-height: 17px;

  @include mediaQuery(max, 960px) {
    font-size: 12px;
    line-height: 24px;
  }
}

.boldPrice {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;

  &.lessBoldPrice {
    font-size: 14px;
    font-weight: 600;
  }
}

.smallCopy {
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
}

.smallPrice {
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
}

.default {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.noDatePricingInfo {
  border-radius: 4px;
  border: 1px dashed $saldova;
  padding: 12px;
  gap: 4px;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-family: $font-primary;
  font-weight: 600;
  line-height: 12px;
  background: none;
  color: $saldova;
}
