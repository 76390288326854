@import '../../../../styles/styles';

.picker {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;

  @include mediaQuery(max, $tablet) {
    margin-bottom: 0.5rem;
    padding: 0.5rem 0.5rem;
    margin: 0 -0.5rem;

    @include searchPageOnly {
      @include touchHilight(200ms);
    }
  }
}

.label {
  font-family: $font-primary;
  font-size: 0.875rem;
  font-weight: 600;
  margin-right: 2rem;
}
