@import '../../../styles/colors';
@import '../../../styles/styles';

.clickableFiltersList {
  display: flex;
  white-space: nowrap;
  flex-flow: row wrap;
  gap: 15px;
  margin-bottom: 10px;

  @include mediaQuery(max, $mobile) {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 2rem;
    margin-bottom: 0px;
  }

  button {
    padding: 0.7rem;
    border: 1.5px solid #a0bcbf;
    border-radius: 4px;
    width: auto;
    background-color: $white;
    display: inline-flex;
    font-weight: normal;
    font-size: 14px;

    svg {
      margin-top: 3px;
      margin-left: 5px;
    }
  }

  .clickableFilterBtn {
    margin-bottom: 0px;
  }
}
