@import '../../../styles/styles';

.resetButtons {
  display: inline-flex;
  gap: 0.7rem;

  button {
    background-color: $saldova;
    font-size: 14px;
    display: flex;
    align-items: center;
    font-family: $font-secondary;
    height: 2.5rem;
    color: $white;

    svg {
      margin-left: 10px;
    }

    svg > path {
      fill: $white;
    }
  }
}
