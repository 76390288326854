@import '../../styles/styles';

.searchTopBarWrapper {
  padding-top: 94px;
  transition: $standardTransition;

  @include searchPageOnly {
    transition: none;
    padding-top: 0px !important;
    margin-top: 82px;

    @include mediaQuery(max, $tablet) {
      margin-top: 68px;
    }
    @include mediaQuery(max, $tablet - 1px) {
      margin-top: 0px;
    }
  }

  @include mediaQuery(max, $tablet) {
    padding-top: 0;
    position: sticky;
    top: 0;
    z-index: 10;
  }
}

.searchTopBarWrapperHidden {
  display: none;
  transition: $standardTransition;
}

.searchIcon {
  height: 100%;
  width: 100%;
}

.searchWrapper {
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  position: relative;

  &.searchWrapperOverlay {
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      filter: brightness(50%);
      z-index: 2;
    }
  }
}

.mobileSearchBar {
  align-items: center;
  background-color: $white;
  border-bottom: 1px solid $input-border;
  border-top: 1px solid $input-border;
  display: flex;
  justify-content: center;
  padding: 0.5rem 1rem;

  @include mediaQuery(max, $mobile) {
    top: 77px;
    width: 100%;
  }

  @include retinaQuery(max, $mobile) {
    top: 64px;
  }

  div {
    width: 100%;
  }

  input {
    min-width: 100%;
  }
}

.mobileSearchBarActive {
  @include mediaQuery(min, $tablet) {
    position: absolute;
    position: relative;
    z-index: 11;
  }
}

.mobileSearchBarHidden {
  @extend .mobileSearchBar;

  position: absolute;
  transition: $standardTransition;

  @include mediaQuery(max, $tablet) {
    top: -70px;
    z-index: 0;
  }
}
