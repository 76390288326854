@mixin mediaQuery($minOrMax, $val) {
  @media only screen and (#{$minOrMax}-width: $val) {
    @content;
  }
}

@mixin mediaQueryAnd($min, $max) {
  @media only screen and (min-width: $min) and (max-width: $max) {
    @content;
  }
}

@mixin transition($transitionProperty) {
  transition: $transitionProperty $standardTransitionDuration
    $standardTransitionTimingFunction;
}

@mixin Ellipsis {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin multiLineEllipsis($lineHeight, $lineCount) {
  line-height: $lineHeight;
  max-height: $lineHeight * $lineCount;
  overflow: hidden;
  position: relative;

  &:before {
    bottom: 0;
    content: '...';
    position: absolute;
    right: 0;
  }
}

@mixin btn {
  border-radius: 4px;
  display: inline-block;
  font-weight: 600;
  max-height: 50px;
  text-decoration: none;
}

@mixin textFieldButton($color, $img, $disImg) {
  background-image: $img;
  background-position: right;
  background-repeat: no-repeat;
  border: none;
  color: lighten($color, 5);
  display: block;
  font-weight: 600;
  min-width: 120px;
  text-decoration: none;
  text-transform: capitalize;

  &:hover,
  &.hovered {
    color: $color;
  }

  &:disabled {
    background-image: $disImg;
    color: lighten($color, 30);
  }
}

@mixin backgroundGradient {
  background-image: linear-gradient(
    90deg,
    $divider 0%,
    $divider 20%,
    #cfcfcf 40%,
    $divider 60%,
    $divider 100%
  );
  background-size: 950px;
}

@mixin backgroundGradientTransparent {
  background-image: linear-gradient(
    90deg,
    rgba(207, 207, 207, 0.1) 0%,
    rgba(207, 207, 207, 0.1) 20%,
    #cfcfcf 40%,
    rgba(207, 207, 207, 0.1) 60%,
    rgba(207, 207, 207, 0.1) 100%
  );
  background-size: 950px;
}

@mixin retinaQuery($minOrMax, $val) {
  @media only screen and (-webkit-min-device-pixel-ratio: 2)      and (#{$minOrMax}-width: $val),
  only screen and (   min--moz-device-pixel-ratio: 2)      and (#{$minOrMax}-width: $val),
  only screen and (   -moz-min-device-pixel-ratio: 2)      and (#{$minOrMax}-width: $val),
  only screen and (     -o-min-device-pixel-ratio: 2/1)    and (#{$minOrMax}-width: $val),
  only screen and (        min-device-pixel-ratio: 2)      and (#{$minOrMax}-width: $val),
  only screen and (                min-resolution: 192dpi) and (#{$minOrMax}-width: $val),
  only screen and (                min-resolution: 2dppx)  and (#{$minOrMax}-width: $val) {
    @content;
  }
}

@mixin searchPageOnly() {
  :global(body.Search) & {
    @content;
  }
}

@mixin searchFilterCopy() {
  font-family: $font-primary;
  font-size: 14px;
  color: $searchFilterText;
  font-weight: 500;
}

@mixin verticalAlign() {
  display: flex;
  align-items: center;
}

@mixin noWebkitHilight {
  -webkit-tap-highlight-color: transparent !important;
  -webkit-touch-callout: none !important;
}

@mixin touchHilight($transitionTime) {
  transition: $transitionTime;
  &.touchHilight {
    @include noWebkitHilight;
    transition: background-color ease-in-out;
    background-color: #ebebeb !important;
  }
}

// responsivePageLayout section gutters
@mixin mobileGutters {
  padding-right: 24px;
  padding-left: 24px;
}

@mixin tabletGutters {
  padding-right: 48px;
  padding-left: 48px;
}

@mixin desktopGutters {
  padding-right: calc((100% - 1100px) / 2);
  padding-left: calc((100% - 1100px) / 2);
}

// responsivePageLayout vertical section padding
@mixin smallYPadding {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

@mixin largeYPadding {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

@mixin reverseColumn {
  flex-direction: column-reverse;
  justify-content: flex-start;
  align-items: flex-start;
}

@mixin btn-secondary {
  @include btn;
  background-color: $white;
  border: 1.7px solid $light-sea-green;
  color: $light-sea-green;
  padding: 15px 24px;

  &:hover,
  &.hovered {
    border-color: $pine-green;
    color: $pine-green;
  }

  &:disabled {
    background-color: darken($white, 1);
    border-color: lighten($light-sea-green, 25);
    color: lighten($light-sea-green, 25);
  }
}
