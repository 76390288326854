// Class Specific Styling
.globalLoader {
  background-color: $white;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 8;
}

.section {
  background-color: $white;
  padding: 5rem;

  @include mediaQuery(max, 1085px) {
    padding: 3rem;
  }
  @include mediaQuery(max, $mobile) {
    padding: 3rem 1rem;
  }
}

.container {
  margin: 0 auto;
  max-width: $maxContentWidth;

  @include mediaQuery(max, $mdDesktop) {
    max-width: 915px;
  }

  @include mediaQuery(max, 1023px) {
    max-width: 100%;
    padding: 3rem 0 1rem;
  }
}

// Labels
.section-label {
  display: block;
  font-family: $font-secondary;
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: 2.5px;
  line-height: 1.2rem;
  text-transform: uppercase;

  @include mediaQuery(max, $mobile) {
    font-size: 0.875rem;
  }
}

.section-label-sm {
  @extend .section-label;
  font-size: 1rem;
  line-height: 1.05rem;

  @include mediaQuery(max, $tablet) {
    font-size: 0.75rem;
  }
  @include mediaQuery(min, 899px) {
    margin: 0.5rem 0 1rem;
  }
}

// Titles
.section-title {
  color: $blue-extra-dark;
  display: block;
  font-family: $font-secondary;
  margin: 1rem 0 0.5rem;
  letter-spacing: -0.38px;

  @include mediaQuery(max, $mobile) {
    letter-spacing: -0.22px;
    font-size: 2.1875rem;
  }
}

.section-title-sm {
  @extend .section-title;
  font-size: 2.375rem;
  line-height: 2.75rem;
  margin: 8px 0;

  @include mediaQuery(max, $tablet) {
    font-size: 1.5rem;
    line-height: 1.75rem;
  }
}

// Subtitles
.section-subtitle {
  color: $saldova;
  display: block;
  font-family: $font-primary;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.65rem;
  margin-bottom: 1rem;

  @include mediaQuery(max, $tablet) {
    font-size: 1.4rem;
    line-height: 1.45rem;
  }

  @include mediaQuery(max, $mobile) {
    font-size: 1rem;
    line-height: 1.25rem;
  }
}

.section-subtitle-sm {
  @extend .section-subtitle;
  font-size: 1.125rem;
  font-weight: 300;

  @include mediaQuery(max, $mobile) {
    font-size: 0.9375rem;
  }
}

.lessBold {
  font-weight: 500;
}

.bold {
  font-weight: 600;
}

// Colors
.blue {
  color: $evolveBlue;
}

.white {
  color: $white !important;
}

// buttons
.btn-primary {
  @include btn;

  background-color: $capeCoral;
  border: none;
  color: $white;
  line-height: 0.9375rem;
  padding: 17px 33px;
  text-align: center;
  white-space: nowrap;

  &:hover,
  &.hovered {
    background-color: $darkRed;
  }

  &:disabled {
    background-color: lighten($red, 30);
  }
}

.btn-secondary {
  @include btn-secondary;
}

.btn-text-field {
  @include textFieldButton(
    $blue,
    url('/assets/icons/icon-caretBlue.svg'),
    url('/assets/icons/icon-caretLight.svg')
  );
  img {
    margin-left: 0.375rem;
    position: relative;
    top: 1px;
  }
}

.btn-noStyle {
  background-color: transparent;
  border: none;
  color: $light-turquoise;
  font-size: 1rem;
  margin: 0;
  padding: 0;
}

.btn-viewMore {
  background-color: transparent;
  border: none;
  color: #48b1c5;
  font-size: 0.875rem;
  margin: 0;
  padding: 0;
  text-decoration: underline;
}

.btn-hidden {
  display: none;
}

.standard-0 {
  color: $evolveBlue;
}

.standardBubble-0 {
  background-color: $evolveBlue;
}

.standard-1 {
  color: $blue-extra-dark;
}

.standardBubble-1 {
  background-color: $blue-extra-dark;
}

.standard-3 {
  color: $dark-green;
}

.standardBubble-3 {
  background-color: $dark-green;
}

.standard-2 {
  color: $portOrange;
}

.standardBubble-2 {
  background-color: $portOrange;
}

.processItemIconWrapper-0 {
  background-color: $dark-orange;
  border-color: $salomie;
}

.processItemIconWrapper-1 {
  background-color: $light-sea-green;
  border-color: $turquoise-blue;
}

.processItemIconWrapper-2 {
  background-color: $dark-green;
  border-color: $mindaro;
}

.processItemIconWrapper-3 {
  background-color: $cyprus;
  border-color: $blumine;
}

.left-aligned {
  text-align: left !important;
}

.center-aligned {
  text-align: center;
}

// Body Scrolling
.noScroll {
  overflow: hidden;
  width: 100%;
}

body.noScroll {
  width: 100%;
}

.idleModalActive {
  overflow: hidden;
}

.fullWidth {
  width: 100%;
}

#hs-form-iframe-0 {
  width: 100% !important;
}

.d-inline {
  display: inline !important;
}

.d-flex {
  display: flex;
}

.hiddenMobile {
  @include mediaQuery(max, $lgTablet) {
    display: none;
  }
}

.hiddenDesktop {
  @include mediaQuery(min, $lgTablet) {
    display: none;
  }
}
