@import 'styles/styles';

.select {
  background-color: transparent;
  font-size: 16px;
  height: 3.25rem;
  max-height: 50px;
  position: relative;
  min-width: 12rem;

  &.small {
    min-width: 9rem;

    @include searchPageOnly {
      height: 40px;
      @include mediaQuery(max, $tablet) {
        @include noWebkitHilight;
      }
    }
  }

  &.small {
    .value {
      height: 45px;
      line-height: 1rem;
      font-size: 0.875rem;
      padding: 12px 1rem;
    }
  }

  &.tiny {
    height: 2rem;

    .value {
      font-size: 0.75rem;
      line-height: 0.875rem;
      padding: 0.5rem 0.625rem;
      position: relative;
    }

    .icon {
      display: none;
    }
  }

  &.withIcon {
    &.small {
      .value {
        padding-left: 2rem;

        @include searchPageOnly {
          padding-left: 0px;
        }
      }
    }
  }

  &.direction-up {
    .options {
      bottom: calc(100% + 0.5rem);
      right: 0;
      top: auto;
    }
  }

  .value {
    @include transition(border-color);
    @include Ellipsis;
    background-color: $white;
    border: 1px solid $inputBorder;
    border-radius: 4px;
    bottom: 0;
    color: $blue-placeholder;
    cursor: pointer;
    display: inline-block;
    font-size: 16px;
    font-weight: 500;
    left: 0;
    line-height: 0.75rem;
    padding: 1rem 2rem 1rem 2.75rem;
    position: absolute;
    right: 0;
    top: 0;

    span {
      color: $casal;
    }

    @include searchPageOnly {
      height: 40px;
      @include searchFilterCopy;
      @include verticalAlign;

      &.focused {
        span {
          font-weight: bold;
        }
        border: 3px solid $inputBorder;
      }
    }

    &.guestPickerError {
      border: 2px solid $red;
      transition: none;

      .guestsCopy {
        color: $red;
      }
    }

    @include mediaQuery(min, $tablet) {
      font-size: 1rem;
      line-height: 1rem;
    }
    .select.hasValue & {
      color: $blue-dark;
    }
    .select.hasTotal & {
      font-weight: 600;
    }
    &.focused {
      border-color: $blue;
    }

    &.focused.guestPickerError {
      border-color: $red;
    }
  }

  .caret {
    position: absolute;
    right: 0.625rem;
    top: calc(50% + 1px);
    transform: translateY(-50%) rotate(180deg);

    &.focused {
      transform: translateY(-50%);
    }
  }

  .guestsCopy {
    @include searchPageOnly {
      margin-left: 30px;
    }
  }

  .icon {
    left: 0.625rem;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    @include searchPageOnly {
      left: 8px;
      height: 18px;
      width: 18px;
    }
  }
}

.options {
  background-color: $white;
  box-shadow: $standardBoxShadow;
  color: $blue-dark;
  padding: 1rem 1rem 0;
  position: absolute;
  top: calc(100% + 1.5rem);
  display: none;
  z-index: 7;

  @include searchPageOnly {
    border-radius: 4px;
  }

  &.focused {
    visibility: visible;
    display: flex;
    flex-flow: column;

    @include searchPageOnly {
      z-index: 10;
    }
  }

  @include mediaQuery(max, $tablet) {
    bottom: 0;
    left: 0;
    padding: 1rem 0 0;
    position: fixed;
    right: 0;
    top: 0;
  }

  @include mediaQuery(min, $tablet) {
    padding: 1rem 0;

    .guestHeader,
    .guestSection,
    .guestsDetails,
    .petsContainer {
      padding: 0 1rem;
    }

    .guestsDetails {
      order: 3;
      font-size: 12px;
      padding: 8px 32px;
    }

    .guestSection {
      order: 1;
    }

    .petsContainer {
      order: 2;
    }

    .footer {
      order: 4;
      margin: 0;
      padding: 0;
      width: 100%;
      padding: 1rem 1rem 0;
    }
  }

  .small & {
    @include mediaQuery(min, $tablet + 1px) {
      top: calc(100% + 0.5rem);
    }
  }

  > div {
    align-items: center;
    display: flex;
    flex-direction: row;

    @include mediaQuery(max, $tablet) {
      padding: 0 1rem;
    }

    > div {
      flex-grow: 1;
      width: 10rem;
    }
  }

  .control {
    align-items: center;
    display: flex;
    flex-direction: row;
    opacity: 1;
    justify-content: space-between;
    padding: 1rem 0 1rem 1rem;

    button {
      touch-action: manipulation;
      background-color: transparent;
      border: none;
      height: 2rem;
      min-height: 2rem;
      min-width: 2rem;
      padding: 0;
      position: relative;
      width: 2rem;

      &:disabled {
        cursor: text;
        opacity: 0.25;
      }
      svg {
        touch-action: manipulation;
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }

    > div {
      margin: 0 2rem;
    }
  }
}

.guestsDetails {
  color: $saldova;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  margin: 0;
  padding: 16px 40px;
  position: relative;

  &:before,
  &:after {
    content: '';
    position: absolute;
    left: 0;
    height: 1px;
    width: 100%;
    background-color: $grey-transparent;
  }

  &:before {
    top: 0;
  }

  &:after {
    bottom: 0;
  }
}

.guestLabel {
  color: $blue-extra-dark;
  font-weight: 600;
}

.subLabel {
  color: $grey-dark;
  font-size: 0.75rem;
  font-weight: 400;
  margin-top: 0.5rem;
}

.radioControls {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 1rem 0 1rem 1rem;

  label {
    display: inline-block;
    height: 2rem;
    margin-right: 2rem;
    position: relative;
  }
  > div:last-of-type {
    label {
      margin-right: 0;
    }
  }
}

.footer {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-left: -2rem;
  margin-top: 1rem;
  width: calc(100% + 4rem);

  @include mediaQuery(max, $tablet) {
    bottom: 0;
    left: 0;
    margin-left: 0;
    padding: 1rem;
    position: fixed;
    right: 0;
    width: 100%;

    &:before {
      top: 0;
    }
  }

  @include searchPageOnly {
    @include mediaQuery(max, $tablet) {
      &:before {
        bottom: 100px;
      }
    }
  }
}

.guestHeader {
  align-items: center;
  border-bottom: 1px solid rgba(10, 63, 70, 0.1);
  display: none;
  justify-content: space-between;
  padding: 0 1rem 1rem;

  @include mediaQuery(max, $tablet) {
    display: flex;

    > div:nth-child(1),
    div:nth-child(3) {
      flex: 0 0 85px;
    }

    > div {
      display: flex;
      flex: 1;
      justify-content: center;

      &:first-of-type button {
        margin-right: auto;
      }
      &:last-of-type button {
        margin-left: auto;
      }
    }
  }
}

.btn {
  background-color: transparent;
  border: none;
  color: $blue;
  font-size: 0.875rem;
  padding: 0;

  &:disabled {
    opacity: 0.5;
  }
}

.headerLabel {
  color: $blue-dark;
  font-weight: 600;
}

.applyBtn {
  width: 100%;
}

.guestSection {
  @include searchPageOnly {
    @include mediaQuery(max, $tablet) {
      @include touchHilight(200ms);
    }
  }
}
