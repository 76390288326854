@import '../../../styles/styles';

.map {
  cursor: pointer;
  height: calc(100vh - 154px);
  position: relative;
  flex: 1 0 350px;

  /**
  width of Algolia GeoSearch component must be whole number, 
  otherwise sub-pixel values can cause Google Maps to zoom out
  see: https://github.com/algolia/react-instantsearch/issues/1737
  */

  @include mediaQuery(max, $lgTablet) {
    bottom: 0;
    height: 100vh;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 7;
  }
}

.mapHidden {
  position: absolute;
  visibility: hidden;
  z-index: -99;
}

.fabMap {
  align-items: center;
  background-color: $white;
  border: none;
  border-radius: 50%;
  box-shadow: 0 5px 10px 0 rgba(9, 63, 69, 0.3);
  height: 50px;
  justify-content: center;
  position: fixed;
  right: 1rem;
  top: 1rem;
  width: 50px;
  z-index: 9;
  transition: none;

  @include mediaQuery(max, $lgTablet) {
    display: flex;
  }
}

.mapFilters {
  align-items: center;
  background-color: $white;
  border: none;
  box-shadow: 0 5px 10px 0 rgba(9, 63, 69, 0.3);

  font-size: 0.75rem;
  font-weight: 600;
  height: 32px;
  justify-content: center;
  right: 50%;
  padding: 0 1rem 0 0.75rem;
  position: fixed;
  top: 1.5rem;
  transform: translateX(50%);
  z-index: 9;

  span {
    margin-left: 0.5rem;
  }

  @include mediaQuery(max, $lgTablet) {
    display: flex;
  }
}
