@import '../../styles/styles';

.checkbox {
  align-items: flex-start;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 0.5rem;
  position: relative;

  @include searchPageOnly {
    @include noWebkitHilight;
    @include mediaQuery(max, $tablet) {
      align-items: center;
      margin-bottom: 0.25rem;
      padding-left: 0.5rem;
      padding-top: 0.77rem;
      padding-bottom: 0.66rem;
      margin: 0 -0.5rem;
      line-height: 1;
      @include touchHilight(200ms);
    }
  }

  input {
    display: none;

    &:checked + label:before {
      background-image: url('/assets/icons/icon-checkboxChecked.svg');
    }
    &:disabled + label {
      cursor: text;
      opacity: 0.4;
    }
  }

  label {
    cursor: pointer;
    font-size: 0.875rem;
    display: flex;

    @include searchPageOnly {
      align-items: center;
      @include mediaQuery(max, 900px) {
        margin: 0px;
      }
    }

    &:before {
      background-image: url('/assets/icons/icon-checkbox.svg');
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      content: '';
      cursor: pointer;
      display: inline-block;
      flex: 0 0 0.875rem;
      height: 0.875rem;
      margin-right: 0.5rem;
      position: relative;
      top: 1px;
      width: 0.875rem;

      @include searchPageOnly {
        height: 24px;
        width: 24px;
        flex: 0 0 24px;
        @include mediaQuery(max, 900px) {
          top: auto;
          margin-right: 0.5rem;
        }
      }
    }

    &.hasError {
      &:before {
        background-image: url('/assets/icons/icon-checkboxError.svg');
      }
    }
  }

  .checkboxErrorMsg {
    color: $glenRose;
    display: block;
    font-size: 16px;
    font-weight: 600;
    line-height: 18px;
    margin-top: 6px;
  }
}
