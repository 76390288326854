@import '../../../../styles/styles';
@import '../../../../styles/select';
@import '../../../../styles/helpers';

.selectWrapper {
  width: 129px;
  margin-right: 8px;
  position: relative;
}

.expandedFilter {
  min-width: 9.5rem !important;
}

.select {
  @extend .select-small;
  background-color: transparent;

  @include mediaQuery(min, $tablet) {
    min-width: 9.5rem;
  }

  .value {
    > strong {
      font-weight: 500;
    }
    svg {
      pointer-events: none;
    }
    &.focused {
      > strong {
        font-weight: bold;
      }
      border: 3px solid $inputBorder;
    }
  }

  .options {
    background-color: $white;
    box-shadow: $standardBoxShadow;
    border-radius: 4px 4px 0 0;
    width: 550px;
    //padding: 1rem 1rem 0;
    display: none;
    z-index: 6;
    top: calc(100% + 30px);
    left: -150px;
    max-height: calc(100vh - 370px);
    min-height: 150px;

    &.focused {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    @include mediaQuery(min, $mdDesktop) {
      left: 0;
    }
    @include mediaQuery(max, $tablet) {
      box-shadow: none;
    }

    .footer {
      border-top: none;
      border-radius: 0 0 4px 4px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin: 1rem 0 0 0;
      width: 100%;
      padding: 1rem 2rem;
      height: 88px;
      bottom: -88px;
      left: 0;
      right: 0;
      position: absolute;
      background-color: #fff;
    }

    .btn {
      background-color: transparent;
      border: none;
      color: $blue;
      padding: 0;

      &:disabled {
        cursor: text;
        opacity: 0.5;
      }
      &.submitBtn {
        font-size: 1rem;
        @extend .btn-primary;
        margin-left: 2rem;
        padding: 1rem 3.125rem;
      }
    }

    > div {
      align-items: flex-start;
      display: flex;
      justify-content: space-between;
      height: 100%;
      overflow-y: auto;

      @include mediaQuery(max, $tablet) {
        display: block;
      }

      > div {
        @include mediaQuery(max, $tablet) {
          padding: 0;
        }
        h3 {
          font-family: $font-primary;
          font-size: 1rem;
          font-weight: 600;
          letter-spacing: -0.27px;
          margin-bottom: 8px;
          text-transform: none;

          @include mediaQuery(max, $tablet) {
            font-family: $font-primary;
            font-size: 0.75rem;
            letter-spacing: 1px;
            margin-bottom: 1rem;
            text-transform: uppercase;
          }
        }
      }
    }
  }
}

.amenities {
  & > div > div {
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    height: 100%;

    & > div {
      width: 50%;
      height: 100%;
    }

    @include mediaQuery(max, $tablet) {
      column-count: 1;
      column-gap: 0;
    }
  }

  button {
    margin-bottom: 34px;
  }
}

.shadowed {
  box-shadow: 3px 3px 5px 0 rgba(10, 63, 70, 0.1),
    -3px -3px 5px 0 rgba(10, 63, 70, 0.05);
}
