@import '../../styles/styles';

.mobilePagination {
  padding: 0 30px;
  position: relative;
  width: 100%;

  a {
    display: block;
    position: absolute;
    transform: translate(-50%, 150%);
  }
}

.mobilePaginationHidden {
  padding: 0 0.5rem;
}
