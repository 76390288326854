@import 'styles/styles';

.cancellationPolicyContainer {
  display: flex;
  flex-direction: row;
  gap: 10px;

  .icon__wrapper {
    position: relative;
    width: 30px;
    height: 20px;

    @include mediaQuery(min, $desktop) {
      padding-block: 0;
    }

    @include mediaQuery(max, $mdDesktop) {
      padding-block: 2px;
    }

    @include mediaQuery(max, $mobile) {
      padding-block: 3px;
    }
  }

  .cancellationPolicyText {
    font-style: italic;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
  }
}
