.select {
  background-color: transparent;
  position: relative;

  &.hasTotal .value {
    font-weight: 600;
  }

  &.hasError .value {
    border-color: $capeCoral;
  }

  .value {
    @include transition(border-color);
    @include Ellipsis;
    background-color: $white;
    border: 1px solid $inputBorder;
    border-radius: 4px;
    bottom: 0;
    color: $blue-placeholder;
    cursor: pointer;
    display: inline-block;
    font-size: 1rem;
    font-weight: 500;
    left: 0;
    line-height: 1rem;
    padding: 1rem 2rem 1rem 1rem;
    position: absolute;
    right: 0;
    top: 0;

    &.hasIcon {
      padding-left: 2.75rem;
    }
    &.focused {
      border-color: $blue;

      .caret {
        transform: translateY(-50%);
      }
    }

    @include mediaQuery(max, $tablet) {
      line-height: 0.8rem;
    }
  }

  .caret {
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%) rotate(180deg);
  }

  .icon {
    left: 0.625rem;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  .options {
    background-color: $white;
    box-shadow: $standardBoxShadow;
    color: $blue-dark;
    padding: 2rem 2rem 0;
    position: absolute;
    top: calc(100% + 0.5rem);
    z-index: 6;

    @include mediaQuery(max, $tablet) {
      bottom: 0;
      box-shadow: none;
      left: 0;
      padding: 1rem 0 0;
      position: fixed;
      right: 0;
      top: 0;
    }
  }

  .control {
    cursor: pointer;
    opacity: 0;
    width: 100%;
    border: 1px solid $inputBorder;
  }

  .btn {
    background-color: transparent;
    border: none;
    color: $blue;
    font-size: 0.75rem;
    padding: 0;

    &:disabled {
      opacity: 0.5;
    }
    &.clearBtn {
      color: #48b1c5;
      font-size: 1.125rem;
      font-weight: semi-bold;
    }
  }

  .footer {
    border-top: 1px solid rgba(10, 63, 70, 0.1);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: -2rem;
    margin-top: 2rem;
    padding: 1rem 2rem;
    width: calc(100% + 4rem);

    @include mediaQuery(max, $tablet) {
      bottom: 0;
      left: 0;
      margin-left: 0;
      padding: 1rem;
      position: fixed;
      right: 0;
      width: 100%;
    }
  }
}

.select-small {
  @extend .select;
  font-size: 0.875rem;
  height: 3.25rem;

  @include mediaQuery(min, $tablet) {
    height: 3.5rem;
  }
  @include mediaQuery(min, 1170px) {
    min-width: 6rem;
  }

  .value {
    height: 2.5rem;
    font-size: 0.875rem;
    line-height: 1.125rem;
    padding: 0.6875rem 1rem;
    border: 1px solid $inputBorder;
    border-radius: 4px;

    @include searchPageOnly {
      @include searchFilterCopy;
      @include verticalAlign;
    }
  }

  .caret {
    position: absolute;
    right: 0.625rem;
    top: calc(50% + 1px);
    transform: translateY(-50%) rotate(180deg);
  }
}
