@import '../../../styles/styles';

.noResults {
  background-color: rgba(235, 235, 235, 0.25);
  border: 1px solid $grey-light;
  border-radius: 2px;
  padding: 18px 24px;
  position: relative;

  h2 {
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 4px;
  }

  p {
    font-size: 16px;
    line-height: 21px;
    max-width: 624px;
  }
}
