@import '../../../../styles/styles';
@import '../../../../styles/select';

.select {
  @extend .select-small;
  @include mediaQuery(min, $tablet) {
    min-width: 7.5rem;
  }

  .value {
    span,
    svg {
      pointer-events: none;
    }
    &.focused {
      span {
        font-weight: bold;
      }
      border: 3px solid $inputBorder;
    }
  }

  &.expanded {
    height: auto;
    .options {
      padding: 0 0 1rem;
      position: relative;
      min-width: 0;
    }
  }

  & > .options {
    display: none;
    border-radius: 4px;
    top: calc(100% + 30px);
    min-width: 380px;

    &.optionsOpen {
      display: block;
    }

    h3 {
      font-family: $font-primary;
      font-size: 0.75rem;
      font-weight: 700;
      margin-bottom: 1rem;
      letter-spacing: 1px;
      text-transform: uppercase;

      @include mediaQuery(max, $tablet) {
        margin-bottom: 1.5rem;
      }
    }
    > div {
      overflow: hidden;
    }
  }

  .footer {
    border-top: none;
  }
}

.rangeSlider {
  & > svg {
    position: relative;
    left: 0;
    top: 17px;
    width: calc(100% - 24px);
    margin: 0 12px;
  }
  margin: 0 0 2rem;
  padding: 0 24px;
}

.priceLabels {
  align-items: center;
  display: flex;
  margin-bottom: 8px;

  & > span {
    width: 120px;
    font-size: 0.875rem;
    color: $saldova;

    &:first-child {
      margin-right: 45px;
    }
  }
}

.priceInputs {
  align-items: center;
  display: flex;

  @include mediaQuery(max, $tablet) {
    justify-content: left;
    width: 100%;
  }
  input {
    -moz-appearance: textfield;
    border-radius: 2px;
    border: 2px solid $seattle;
    font-size: 0.875rem;
    height: 40px;
    line-height: 1;
    padding: 0.5rem 1rem 0.5rem 1.5rem;
    width: 120px;

    &::placeholder {
      color: #cfcfcf;
    }
  }

  input::-webkit-inner-spin-button,
  input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  span {
    color: $saldova;
    display: block;
    font-size: 0.625rem;
    letter-spacing: 0.5px;
    margin: 0 15px;

    font-size: 0.875rem;
  }
}

.iconInput {
  position: relative;
  width: 120px;
  & > i {
    position: absolute;
    display: block;
    transform: translate(0, -50%);
    top: 50%;
    pointer-events: none;
    width: 21px;
    text-align: right;
    font-style: normal;
    font-size: 0.875rem;
  }

  & > input.boldPlaceholder::placeholder {
    color: black;
  }
}
