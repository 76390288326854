@import '../../styles/styles';

.control {
  align-items: center;
  display: flex;
  justify-content: space-between;

  button {
    background-color: transparent;
    border: none;
    color: $blue;
    height: 2rem;
    min-height: 2rem;
    min-width: 2rem;
    padding: 0;
    position: relative;
    width: 2rem;

    &:disabled {
      cursor: text;
      opacity: 0.25;
    }
    img {
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  > div {
    color: $casal;
    font-size: 0.75rem;
    margin: 0 1.5rem;
  }
}
