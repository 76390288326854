@import '../../../styles/styles';

.customMarker {
  overflow: visible !important;
  font-family: $font-tertiary;
  font-size: 14px;
  font-weight: 600;
  line-height: 32px;
  text-align: center;
  position: relative;
  top: -2px;
  left: -1px;

  &.favorited {
    &:after {
      font-family: 'Font Awesome 5 Free';
      content: ' \f004';
      font-size: 10px;
      color: #d24f45;
      position: relative;
      top: -1px;
    }
  }
}

.favoriteIcon {
  margin-left: 2px;
  width: 10px;
  height: 10px;
}

.infoContainer {
  background-color: $white;
  border-radius: 4px;
  bottom: 50px;
  box-shadow: 0 10px 20px rgba(9, 63, 69, 0.3);
  display: flex;
  flex-direction: column;
  flex: 0 0 220px;

  max-width: 220px;
  width: 220px;

  &:after {
    position: absolute;
  }
}

.gallery {
  height: auto;
  max-height: 147px;
  width: 100%;
  border-radius: 4px 4px 0 0;
  border: none;
}

.info {
  color: $blue-extra-dark;
  display: block;
  padding: 10px 15px;
}

.infoTop {
  align-items: center;
  display: flex;
}

.type {
  background-color: $dark-cerulean;
  border-radius: 3px;
  color: $white;
  display: inline-block;
  font-size: 10px;
  font-weight: 400;
  margin-right: 0.5rem;
  max-width: 150px;
  letter-spacing: 0.83px;
  line-height: 12px;
  padding: 5px 8px 5px;
  text-transform: uppercase;
  font-family: $font-primary;
  font-weight: 700;
}

.resultDetailRating {
  align-items: center;
  display: flex;

  span {
    font-family: $font-tertiary;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
  }
}

.starRating {
  align-items: center;
  display: flex;
  font-size: 12px;
  font-weight: 600;
  line-height: 14px;
  margin-right: 5px;

  span {
    display: block;
    font-family: $font-tertiary;
    font-weight: 600;
    margin-right: 4px;
  }
}

.reviews {
  color: $casal;
}

.resultDetailsDescriptionChild {
  margin: 7px 0;
  max-width: 100%;
}

.resultHeadline {
  display: block;
  font-family: $font-primary;
  font-size: 14px;
  font-weight: 600;
  max-width: 100%;
  white-space: normal;
}

.resultDetailInfo {
  align-items: center;
  display: flex;
  max-width: 800px;

  &:last-child {
    margin-top: 3px;
  }

  span {
    color: $casal;
    display: block;
    font-family: $font-tertiary;
    font-weight: 600;
    font-size: 12px;
    line-height: 12px;
    margin-right: 5px;
    padding: 0 10px 0 5px;
    position: relative;

    @include mediaQuery(max, 960px) {
      font-size: 12px;
      line-height: 15px;
    }

    &:after {
      bottom: 50%;
      font-size: 18px;
      content: '.';
      position: absolute;
      right: 0;
    }

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      &:after {
        content: '';
      }
    }
  }
}
