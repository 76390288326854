@keyframes panel {
  0% {
    background-position: -950px 0;
  }

  100% {
    background-position: 950px 0;
  }
}

@-o-keyframes panel {
  0% {
    background-position: -950px 0;
  }

  100% {
    background-position: 950px 0;
  }
}

@-ms-keyframes panel {
  0% {
    background-position: -950px 0;
  }

  100% {
    background-position: 950px 0;
  }
}

@-moz-keyframes panel {
  0% {
    background-position: -950px 0;
  }

  100% {
    background-position: 950px 0;
  }
}

@-webkit-keyframes panel {
  0% {
    background-position: -950px 0;
  }

  100% {
    background-position: 950px 0;
  }
}

// Toast enter animation from react-toastify/scss/animations/_slide.scss
@keyframes slideInUp {
  from {
    transform: translate3d(0, 110%, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
.slideInUp {
  animation: slideInUp 0.5s both;
}

@keyframes slideInDown2 {
  from {
    transform: translate3d(0, -110%, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeOutSlow {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
