@import '../../../styles/styles';

.resultsGrid {
  padding: 1rem 1rem 2.5rem;
  position: relative;
  max-width: 1100px;
  width: 100%;
  flex: 2 0 650px;

  @include mediaQuery(min, $tablet) {
    max-height: calc(100vh - 154px);
    height: 100%;
    overflow-y: auto;
  }

  @include mediaQuery(max, $desktop) {
    padding: 1rem 1rem 2.5rem;
  }

  @include mediaQuery(max, $lgTablet) {
    flex: 0 0 100%;
    right: -12px;
    padding-right: 2.25rem;
  }

  @include mediaQuery(max, $mobile) {
    padding: 1rem 1.5rem 2.5rem 0.5rem;
    right: -10px;
  }
}

.resultsGridHidden {
  position: absolute;
  display: none;
  z-index: 0;
}

.gridHelpers {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 12px;

  @include mediaQuery(max, $mobile) {
    margin-bottom: 12px;
  }
}

.topBar {
  display: flex;
  justify-content: space-between;
  margin-bottom: 14px;
}

.fabGrid {
  align-items: center;
  background-color: #a848bd;
  border: none;
  border-radius: 75px;
  bottom: 2rem;
  display: none;
  height: 45px;
  justify-content: center;
  position: absolute;
  right: 1rem;
  width: 140px;
  z-index: 2;
  position: fixed;
  left: calc(50% - 70px);

  > div {
    color: white;
    font-family: $font-primary;
    font-size: 14px;
    padding-left: 10px;
    font-weight: 500;
  }
  @include mediaQuery(max, $lgTablet) {
    display: flex;
  }

  @include mediaQuery(max, $tablet) {
    bottom: 2rem;
  }

  @include retinaQuery(max, $tablet) {
    bottom: 1rem;
  }
}

.resultsGridV1Button {
  display: none;
}
