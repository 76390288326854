@import '../../../styles/styles';

.mapResultsGrid {
  display: flex;
  justify-content: space-between;
  position: fixed;
  display: none;
  left: 0;
  width: 100%;
  bottom: 8px;
  z-index: 0;
}

.active {
  display: block;
  z-index: 8;
}

.hidden {
  position: absolute;
  display: none;
  visibility: hidden;
  z-index: 0;
}
